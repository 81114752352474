@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content{
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.app{
  display:flex;
  position:relative;
}

::-webkit-scrollbar{
  width:10px;
}

::-webkit-scrollbar-track{
  background: #e0e0e0;
}

::-webkit-scrollbar-thumb{
  background: #888;
}

::-webkit-scrollbar-track:hover{
  background: #555;
}

.css-1xsmo0 .MuiDataGrid-columnHeaders {
  background-color: #b7f1ce;
  border-bottom: none;
} 

.css-zc6s6w-MuiTypography-root{
  display: flex;
  justify-content: center;
  align-items: center;
}

.css-8s8saf-MuiTypography-root{
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-1nkm2ms {
  margin-top: 25px;
  margin-left: 15px;
}

@media (max-width: 480px) {
  html,
  body,
  #root,
  .app,
  .content{
    height: 100%;
    width: 120%;
    font-family: "Source Sans Pro", sans-serif;
  }
  
  .css-0 {
    display: flex;
  }
  
  .css-1xn3edc{
    margin-right: 18rem;
  }
  
  .css-y0u0hj {
    width: 50%;
  }
  
  .css-ists20 {
    display: flex
  }
  
  .css-1ula4hh{
    grid-column: span 12;
  }
  
  .css-mb6mia {
    grid-column: span 12;
    grid-row: span 2;
    background-color: #1F2A40;
  }
  
  .css-1r0ei6f {
    grid-column: span 12;
    grid-row: span 2;
    background-color: #1F2A40;
    overflow: auto;
  }
  
  .css-ug68cj{
    margin-right: 80px;
  }
  
  .css-1ii9o6l {
    height: 75vh;
    width: 90vw;
  }
  
  .css-jzq99u {
    margin-left: 20px;
  }
  
  .css-0 {
    display: block;
  }
  
  .css-ykcw1u {
    width: 40%;
  }
  
  .css-1q4o5na {
    width: 50%;
  }
  
  .css-siyejb {
    display: flex;
    justify-content: start;
    margin-right: 20px;
  }
  
  .css-zwiamn {
    margin: 10px;
  }
  
  .css-ists20 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: 140px;
    gap: 20px;
  }
}
