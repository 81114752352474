.css-135do9d {
margin-left: 50px ;
}
.css-ol5wdw {
    margin-left: 10px;
    margin-top: 10px;
}
@media (max-width: 480px) {
.css-jzq99u {
    margin-left: 20px;
  }
  .css-zc6s6w-MuiTypography-root{
    margin-right: 25px;
  }
  .css-1nkm2ms {
    margin-top: 25px;
    margin-left: 15px;
    }
  }